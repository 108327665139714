import React, { Component } from 'react'
import { CModal, CModalHeader, CModalBody, CModalTitle, CForm, CFormInput, CButton } from '@coreui/react'

class ModalUpdateAssignmentFolder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
      modal_data: {
        assignment_folder_name: this.props.assignment_folder ? this.props.assignment_folder.assignment_folder_name : '',
        rank: this.props.assignment_folder ? this.props.assignment_folder.rank : '',
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.assignment_folder && (!prevProps.assignment_folder || prevProps.assignment_folder.id !== this.props.assignment_folder.id)) {
      this.setState({
        modal_data: {
          assignment_folder_name: this.props.assignment_folder ? this.props.assignment_folder.assignment_folder_name : '',
          rank: this.props.assignment_folder ? this.props.assignment_folder.rank : '',
        },
      })
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    let modal_data = this.state.modal_data

    modal_data[nam] = val

    this.setState({ modal_data: modal_data })
  }

  handleClose = () => {
    this.props.handleClose()
  }

  modalSubmit = () => {
    this.props.modalSubmit(this.state.modal_data)
  }

  render() {
    return (
      <CModal visible={this.props.show} onClose={this.handleClose}>
        <CModalHeader closeButton>
          <CModalTitle>แก้ไขกลุ่มของเนื้อหา</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormInput label='ชื่อกลุ่มของเนื้อหา' className='mb-3' type='text' placeholder='' name='assignment_folder_name' value={this.state.modal_data.assignment_folder_name} onChange={this.myChangeHandler} />
            <CFormInput label='ลำดับ' className='mb-3' type='number' min='0' max='1000' step='1' placeholder='' name='rank' value={this.state.modal_data.rank} onChange={this.myChangeHandler} />
            <CButton type='button' className='mb-3' onClick={this.modalSubmit}>
              แก้ไข
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
    )
  }
}

export default ModalUpdateAssignmentFolder
