import React, { Component } from 'react'
import { CButton, CContainer } from '@coreui/react'
import { Link } from 'react-router-dom'

class NotFound extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    console.log('NotFound render')

    return (
      <CContainer className='text-center mt-5' fluid>
        <h1 style={{ fontSize: 'clamp(1px,20vw,250px)', color: '#c7505f' }}>404</h1>
        <h2 className='text-uppercase' style={{ fontSize: 'clamp(1px,10vw,90px)' }}>
          Page Not Found
        </h2>
        <p className='mt-5 text-secondary' style={{ fontSize: 'clamp(1px,5vw,30px)' }}>
          The page you are looking for might have been removed <br /> had it's name changed or is temporarily unavailable.
        </p>
        <CButton size='lg' color='danger' className='mt-2' component={Link} to='/'>
          <span>Click to Homepage</span>
        </CButton>
      </CContainer>
    )
  }
}

export default NotFound
