import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import ActiveCourseDashboard from './active_course'

class MyDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <>
        <FrameHeaderLeftMenu active_menu='dashboard'></FrameHeaderLeftMenu>
        <ActiveCourseDashboard />
      </>
    )
  }
}

export default MyDashboard
