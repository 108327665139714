import React, { Component } from 'react'
import { CModal, CModalHeader, CModalBody, CModalTitle, CTable } from '@coreui/react'

class ModalCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    return this.props.user ? (
      <CModal visible={this.props.show} onClose={this.handleClose}>
        <CModalHeader closeButton>
          <CModalTitle>
            วิชาของผู้เรียน {this.props.user.first_name} {this.props.user.first_name}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CTable striped bordered hover size='sm' className='mt-3'>
            <thead>
              <tr>
                <th>ชื่อวิชา</th>
                <th>% ของการเรียน</th>
              </tr>
            </thead>
            <tbody>
              {this.props.user.user_course.map((user_course) => (
                <tr key={user_course.id}>
                  <th>{user_course.course ? user_course.course.course_name : ''} </th>
                  <td>{user_course.is_course_complete ? '100%' : user_course.learning_progress.toFixed(2) + '%'}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        </CModalBody>
      </CModal>
    ) : (
      <></>
    )
  }
}

export default ModalCourse
