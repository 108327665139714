import React, { Component } from 'react'
import { CAlert, CCard, CForm, CButton, CInputGroup, CFormInput, CCardBody } from '@coreui/react'
import HeaderTop from '../header/top'
import Swal from 'sweetalert2'
import { apiCheckForgotPasswordToken, apiRedeemForgotPasswordToken } from '../../services/api/auth'
import isEmail from 'validator/lib/isEmail'

class AuthRedeemForgorPasswordTokenFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: this.props.match.params.token,
      is_token_valid: null,
      user: null,
      password: '',
      password_re: '',
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeChangePassword = async () => {
    console.log(this.state)

    if (this.state.password.length < 6) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านต้องมีความยาว 6 ตัวอักษร',
      })
      return
    }

    if (this.state.password !== this.state.password_re) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
      })
      return
    }

    try {
      await apiRedeemForgotPasswordToken(this.state.token, this.state.password)
      await Swal.fire({
        icon: 'success',
        text: 'เปลี่ยนแปลงรหัสผ่านสำเร็จ',
      })
      this.props.history.push('/login')
    } catch (e) {
      console.log('e', e)
      await Swal.fire({
        icon: 'error',
        text: 'พบปัญหาไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาทดลองเปลี่ยนรหัสผ่านใหม่',
      })
    }
  }

  componentDidMount() {
    this.checkToken()
  }

  checkToken = async () => {
    try {
      let user = await apiCheckForgotPasswordToken(this.state.token)
      console.log(user)
      this.setState({ is_token_valid: true, user: user.user })
    } catch (e) {
      console.error('e', e)
      this.setState({ is_token_valid: false })
    }
  }

  render() {
    return (
      <>
        <HeaderTop></HeaderTop>
        {this.state.is_token_valid !== null && (
          <CCard className='shadow'>
            <CCardBody>
              <h4 className='text-center text-secondary'>ลืมรหัสผ่าน</h4>
              <hr className='bg-secondary'></hr>
              <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
                {this.state.is_token_valid === true ? (
                  <CForm>
                    <CFormInput label='ชื่อผู้ใช้งาน' id='frmRegisterPassword' type='text' placeholder='' readOnly value={this.state.user.user_method[0].username} />
                    <CFormInput label='รหัสผ่าน' type='password' placeholder='' name='password' onChange={this.myChangeHandler} text='รหัสผ่านต้องมีความยาว 6 ตัวอักษร' />
                    <CFormInput label='ยืนยันรหัสผ่าน' type='password' placeholder='' name='password_re' onChange={this.myChangeHandler} />

                    <CButton type='button' className='' onClick={this.activeChangePassword}>
                      เปลี่ยนรหัสผ่าน
                    </CButton>
                  </CForm>
                ) : (
                  <CAlert color='secondary' className='text-center'>
                    ลิงก์หมดอายุ หรือถูกใช้ในการเปลี่ยนรหัสผ่านไปแล้ว
                  </CAlert>
                )}
              </div>
            </CCardBody>
          </CCard>
        )}
      </>
    )
  }
}

export default AuthRedeemForgorPasswordTokenFrm
