import React, { Component } from 'react'
import { CContainer, CRow, CCol, CButton, CCard, CCardBody, CForm, CFormInput } from '@coreui/react'
import Header from '../header/top'
import Swal from 'sweetalert2'
import { registerUser } from '../../services/api/resgiter'
import isEmail from 'validator/lib/isEmail'

class AuthRegisterFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      password_re: '',
      email: '',
      firstname: '',
      lastname: '',
      job_title: '',
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeRegister = async (event) => {
    console.log(this.state)
    event.preventDefault()
    if (this.state.username.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'กรุณาระบุชื่อผู้ใช้งาน',
      })
      return
    }

    if (this.state.password.length < 6) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านต้องมีความยาว 6 ตัวอักษร',
      })
      return
    }

    if (this.state.password !== this.state.password_re) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
      })
      return
    }

    if (!isEmail(this.state.email)) {
      Swal.fire({
        icon: 'error',
        text: 'อีเมล์ไม่ถูกต้อง',
      })
      return
    }

    let user_info = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      email: this.state.email,
    }
    let user_data = {
      job_title: this.state.job_title,
    }
    let result = await registerUser(this.state.username, this.state.password, user_info, user_data)

    console.log(result)

    if (result.status >= 200 && result.status <= 250) {
      await Swal.fire({
        icon: 'success',
        text: 'สร้างผู้ใช้งานสำเร็จ',
      })
      this.props.history.push('/login')
    } else {
      await Swal.fire({
        icon: 'error',
        text: result.data.message,
      })
    }
  }

  render() {
    return (
      <>
        <Header></Header>
        <div className='bg-welcome' style={{ height: '100vh' }}>
          <CContainer className='w-my' fluid>
            <CCard className='shadow mt-5 w-my mx-auto'>
              <CCardBody>
                <CRow>
                  <CCol>
                    <h4 className='text-center mt-1 text-pri'>สมัครสมาชิก</h4>
                    <hr className='bg-pri text-pri mx-auto'></hr>
                    <CForm>
                      <CFormInput className='mb-2' label='ชื่อผู้ใช้งาน' id='frmRegisterUsername' type='text' placeholder='' name='username' onChange={this.myChangeHandler} />
                      <CFormInput className='mb-2' label='รหัสผ่าน' id='frmRegisterPassword' type='password' placeholder='' name='password' onChange={this.myChangeHandler} text='รหัสผ่านต้องมีความยาว 6 ตัวอักษร' />
                      <CFormInput className='mb-2' label='ยืนยันรหัสผ่าน' id='frmRegisterPasswordRe' type='password' placeholder='' name='password_re' onChange={this.myChangeHandler} />
                    </CForm>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <h4 className='text-center text-pri mt-4'>ข้อมูลส่วนตัว</h4>
                    <hr className='bg-pri text-pri mx-auto'></hr>
                    <CForm onSubmit={this.activeRegister}>
                      <CFormInput className='mb-2' label='ชื่อ' id='frmRegisterFirstname' type='text' placeholder='' name='firstname' onChange={this.myChangeHandler} />
                      <CFormInput className='mb-2' label='นามสกุล' id='frmRegisterLastname' type='text' placeholder='' name='lastname' onChange={this.myChangeHandler} />
                      <CFormInput className='mb-2' label='ตำแหน่ง' id='frmRegisterJobTitle' type='text' placeholder='' name='job_title' onChange={this.myChangeHandler} />
                      <CFormInput className='mb-2' label='อีเมล์' id='frmRegisterEmail' type='email' placeholder='' name='email' onChange={this.myChangeHandler} />
                      <CButton className='w-100 mt-2' color='danger' type='submit'>
                        <div className='text-white fs-5'>สมัครสมาชิก</div>
                      </CButton>
                    </CForm>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </div>
      </>
    )
  }
}

export default AuthRegisterFrm
