import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import lodash, { size } from 'lodash'
import { CContainer, CImage, CRow, CCol, CButton, CListGroup, CListGroupItem } from '@coreui/react'
import { apiGetPublicCourseDetail, apiEnrollCourse } from '../../services/api/course'
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

import LockCourse from '../../assets/images/LockCourse.png'
import bgLeftRightGreyOffice from '../../assets/images/bgLeftRightGreyOffice.png'
import officeLeft from '../../assets/images/officeLeft.png'
import btnLearn from '../../assets/images/btnLearn.png'
import btnStartLearn from '../../assets/images/btnStartLearn.png'

class CourseDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      course: null,
      course_assignment_folders: [],
      course_status: 0, //0 = not register, 1 = learning, 2 = complete
      course_name: '',
      course_detail: '',
    }
  }

  loadCourse = async () => {
    let course_data = await apiGetPublicCourseDetail(parseInt(this.props.match.params.course_id))
    let course_assignment_folders = await apiGetListCourseAssignmentFolder(parseInt(this.props.match.params.course_id))

    let my_info = JSON.parse(localStorage.getItem('user_info'))

    let course_status = 0
    let is_attend = lodash.filter(course_data.data.course_student, { user_id: my_info.id })

    if (is_attend.length > 0) {
      course_status = 1
      if (is_attend[0].is_course_complete === true) {
        course_status = 2
      }
    }

    this.setState({
      course: course_data.data,
      course_status: course_status,
      course_assignment_folders: course_assignment_folders.data,
    })
  }

  componentDidMount() {
    this.loadCourse()
  }

  enterCourse = () => {
    this.props.history.push('/course/' + this.props.match.params.course_id + '/learn')
  }

  enrollCourse = async () => {
    try {
      await apiEnrollCourse(this.props.match.params.course_id)
      await Swal.fire({
        icon: 'success',
        text: 'ลงทะเบียนเช้าเรียนในวิชานี้สำเร็จ',
      })

      this.enterCourse()
    } catch (e) {
      // console.log(e)
      await Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด ไม่สามารถลงทะเบียนในวิชานี้ได้',
      })
    }
  }

  splitString = (text) => {
    const myArray = text.split(',')
    let result = ''
    // console.log('arR', myArray)
    if (myArray.length) {
      for (let index = 0; index < myArray.length; index++) {
        result += '<li>' + myArray[index] + '</li>'
      }
    }
    // document.getElementById('instructor-name').innerHTML = result
    document.getElementById('instructor-name-top').innerHTML = result
    document.getElementById('instructor-name-left').innerHTML = result
  }

  render() {
    if (this.state.course) {
      this.setState({
        course_name: this.state.course.course_name.replaceAll('"', '').replace(')', '').split('-'),
        course_detail: this.state.course.course_data.course_detail_1,
      })
    }
    const bgStyle2 = {
      background: `url(${bgLeftRightGreyOffice}) no-repeat bottom center`,
      backgroundSize: `115%`,
      overflow: 'hidden',
    }
    const bgStyle3 = {
      background: `url(${officeLeft}) no-repeat bottom center, #e0e1e1`,
      backgroundSize: `25%`,
      backgroundPosition: 'bottom left',
      overflow: 'hidden',
    }
    return (
      <>
        <FrameHeaderLeftMenu active_menu='course_list'></FrameHeaderLeftMenu>
        <div className='bg-four'>
          <CContainer fluid>
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }}>
              <CCol>
                <CImage src={LockCourse} fluid />
              </CCol>
              <CCol style={{ marginBottom: '80px' }} className='align-self-center text-white '>
                <h1 className='text-uppercase mt-5 font-h1'>{this.state.course_name[0]}</h1>
                <h2 className='mb-4 text-pri font-h1'>{this.state.course_name[1]}</h2>
                <hr style={{ color: 'white', marginLeft: '0', borderWidth: '1px', width: '100%' }} />
                <h3 className='fw-normal text-sec font-h1' style={{ marginTop: '1em', marginBottom: '1em' }}>
                  {this.state.course_detail.split(' ').reverse().slice(1).reverse().join(' ')}
                </h3>
                <hr style={{ color: 'white', marginLeft: '0', borderWidth: '1px', width: '100%' }} />
                <h2 className='mt-4 fw-normal text-pri font-h1'>
                  <i>{this.state.course_detail.split(' ').pop()}</i>
                </h2>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <div style={bgStyle2}>
          <CContainer>
            <CRow>
              <CCol xs={12} className='mt-5 mb-5 m-auto w-my'>
                <h1 className='text-pri' style={{ lineHeight: '1.5' }}>
                  รายละเอียด
                </h1>
                <p style={{ fontSize: '20px' }}>{this.state.course && this.state.course.course_data.description}</p>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <div style={bgStyle3}>
          <CContainer fluid>
            <CRow className='d-md-none'>
              <CCol xs={12} className='mt-5 mb-5 '>
                <h3 className=' mb-3 text-b text-pri'>เนื้อหาของคอร์สนี้</h3>
                <CListGroup>
                  {this.state.course_assignment_folders.map((folder, i) => (
                    <CListGroupItem className='p-3 bg-transparent ' style={{ fontSize: '18px', borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }} key={i}>
                      <span className='fs-4 text-sec'>{folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;</span>
                      {folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={6} className='mt-md-5 mb-5 text-center'>
                {this.state.course && <CImage align='center' width={428} className='border-image bg-white' src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + this.state.course.course_data.cover_photo} fluid />}
                <div id='monitorLeg' className='mt-2 mb-3 bg-white' style={{ maxWidth: '428px' }}></div>
                <ul id='instructor-name-top' className='d-none d-md-inline-block font-h5'>
                  {this.state.course ? this.splitString(this.state.course.course_data.course_instructor) : null}
                </ul>
                {this.state.course_status === 0 && (
                  <div class='mt-4'>
                    <CButton className='bg-transparent border-0' onClick={this.enrollCourse}>
                      <CImage width={200} src={btnStartLearn} fluid />
                    </CButton>
                  </div>
                )}
                {this.state.course_status === 1 && (
                  <div class='mt-4'>
                    <CButton className='bg-transparent border-0' onClick={this.enterCourse}>
                      <CImage width={200} src={btnLearn} fluid />
                    </CButton>
                  </div>
                )}
                {this.state.course_status === 2 && (
                  <div class='mt-4'>
                    <CButton className='bg-transparent border-0' onClick={this.enterCourse}>
                      <CImage width={200} src={btnLearn} fluid />
                    </CButton>
                    <div className='fs-xs mt-1 text-muted'>*คุณเคยศึกษาบทเรียนนี้จนจบแล้ว</div>
                  </div>
                )}
              </CCol>
              <CCol xs={6} className='d-md-none'>
                <ul id='instructor-name-left' className='d-inline-block font-h5'>
                  {this.state.course ? this.splitString(this.state.course.course_data.course_instructor) : null}
                </ul>
              </CCol>
              <CCol xs={6} className='mt-5 mb-5 d-none d-md-block'>
                <h3 className=' mb-3 text-b text-pri'>เนื้อหาของคอร์สนี้</h3>
                <CListGroup>
                  {this.state.course_assignment_folders.map((folder, i) => (
                    <CListGroupItem className='p-3 bg-transparent ' style={{ fontSize: '18px', borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }} key={i}>
                      <span className='fs-4 text-sec'>{folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;</span>
                      {folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </>
    )
  }
}

export default CourseDetail
