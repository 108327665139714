import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'

class FrameHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <>
        <FrameHeaderLeftMenu active_menu='course_list' />
        <div id='main-container-border'>
          <div id='main-container-border-inner'>
            <div className={this.props.fullscreen === true ? 'container-fluid' : 'container'}>{this.props.children}</div>
          </div>
        </div>
      </>
    )
  }
}

export default FrameHeader
