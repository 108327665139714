import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash'
import { CContainer, CCard, CCardBody, CCardTitle, CButton, CForm, CInputGroup, CTable, CPagination, CPaginationItem, CFormInput } from '@coreui/react'
import { apiGetCourse, apiAddCourse, apiAdminAllCourse } from '../../../services/api/course'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ModalAddCourse from './modal_add_course'
import bgCourse from '../../../assets/images/bgCourse.png'

class AdminCourseList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      count: 0,
      page: 1,
      courses: [],
      keyword: '',
      paginate_items: [],
      show_modal_course: false,
    }
  }

  updatePaginateItem = (page, limit) => {
    let paginate_items = []

    for (let number = 1; number <= this.state.count / limit + 1; number++) {
      paginate_items.push(
        <CPaginationItem
          key={number}
          active={number === page}
          onClick={() => {
            this.loadCourse(number)
          }}>
          {number}
        </CPaginationItem>
      )
    }

    this.setState({
      page: page,
      paginate_items: paginate_items,
      show_modal_course: false,
      modal_course_user: null,
    })
  }

  loadManage = async (course) => {
    setInterval(function () {
      window.location.replace('/admin/course/' + course.id)
    }, 200)
  }

  loadCourse = async (page = 1, limit = 40) => {
    let course_data = await apiAdminAllCourse(page, limit, [['course_name', 'asc']], [{ key: 'course_name', value: this.state.keyword }])

    this.setState({
      page: page,
      count: course_data.data.count,
      courses: lodash.map(course_data.data.data, (course) => {
        return {
          id: course.id,
          course_name: course.course_name,
          course_is_publish: course.course_is_publish,
          course_student_cnt: Array.isArray(course.course_student) ? course.course_student.length : 0,
        }
      }),
    })

    this.updatePaginateItem(page, limit)
  }

  componentDidMount() {
    this.loadCourse()
  }

  loadAddCourseModal = () => {
    this.setState({
      show_modal_course: true,
    })
  }

  createCourse = async (course_name) => {
    console.log('createCourse', course_name)
    try {
      let res = await apiAddCourse({
        course_name: course_name,
      })
      Swal.fire({
        icon: 'success',
        text: 'เพิ่มข้อมูลเสร็จสิ้น',
      })
      setInterval(function () {
        window.location.replace('/admin/course/' + res.data.id)
      }, 1000)
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด',
      })
      return
    }
  }

  render() {
    const bgStyle = {
      backgroundImage: `url(${bgCourse})`,
      backgroundColor: '#edeeee',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'bottom center',
      overflow: 'hidden',
      minHeight: '100vh',
      // justifyContent: 'center',
    }
    return (
      <>
        <FrameHeaderLeftMenu active_menu='admin_course'></FrameHeaderLeftMenu>
        <div style={bgStyle}>
          <CContainer className='w-my' fluid>
            <CCard className='shadow mt-5'>
              <CCardBody>
                <CCardTitle className='mt-3 fs-3 text-pri'>วิชาเรียนในระบบ</CCardTitle>
                <CButton
                  className='btn mb-3 btn-primary btn-md btn-add'
                  type='button'
                  onClick={() => {
                    this.loadAddCourseModal()
                  }}>
                  เพิ่มวิชาเรียน
                </CButton>
                <div className='d-flex justify-content-between '>
                  <div>
                    <CForm
                      className='d-flex'
                      onChange={(evt) => {
                        this.setState({ keyword: evt.target.value })
                      }}
                      onSubmit={(evt) => {
                        evt.preventDefault()
                        this.loadCourse()
                      }}>
                      <CFormInput type='text' size='sm' />
                      <CButton type='submit' size='sm'>
                        ค้นหา
                      </CButton>
                    </CForm>
                  </div>
                  <div>
                    <CPagination size='sm'>{this.state.paginate_items}</CPagination>
                  </div>
                </div>
                <CTable striped bordered hover size='sm' className='mt-3'>
                  <thead>
                    <tr>
                      <th className='text-center'>id</th>
                      <th className='text-center'>ชื่อวิชา</th>
                      <th className='text-center'>เปิดให้งาน</th>
                      <th className='text-center'>ผู้เรียน</th>
                      <th className='text-center'>จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.courses.map((course) => (
                      <tr>
                        <td className='text-center'>{course.id}</td>
                        <td className='text-center'>{course.course_name}</td>
                        <td className='text-center'>{course.course_is_publish ? 'เปิด' : 'ไม่เปิด'}</td>
                        <td className='text-center'>{course.course_student_cnt}</td>
                        <td className='text-center'>
                          <CButton
                            className='btn-submit'
                            type='button'
                            size='md'
                            onClick={() => {
                              this.loadManage(course)
                            }}>
                            จัดการ
                          </CButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CTable>
              </CCardBody>
            </CCard>
            <ModalAddCourse
              show={this.state.show_modal_course}
              handleClose={() => {
                this.setState({ show_modal_course: false })
              }}
              modalSubmit={(course_name) => {
                this.createCourse(course_name)
              }}
            />
          </CContainer>
        </div>
      </>
    )
  }
}

export default AdminCourseList
