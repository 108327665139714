import React, { Component } from 'react'
import { CContainer, CRow, CCol, CImage } from '@coreui/react'
import officeRight from '../../assets/images/officeRight.png'

export default class IntroWelcome extends Component {
  constructor(props) {
    super(props)

    let user_info = null
    if (localStorage.getItem('user_info')) {
      try {
        user_info = JSON.parse(localStorage.getItem('user_info'))
      } catch (e) {}
    }
    this.state = {
      user_info: user_info,
    }
  }
  render() {
    return (
      <div className='bg-welcome'>
        <CContainer className='w-my' fluid>
          <CRow>
            <CCol xs={12} xl={0} className='mt-5 text-white align-self-center d-block d-xl-none'>
              <h3>ยินดีต้อนรับสู่</h3>
              <h1 className='text-pri text-uppercase'>Lifelong Learning Platform</h1>
              <h5 className='fw-light'>เพื่อการเรียนรู้ตามความต้องการ (Learning On-Demand) ของข้าราชการและบุคลากร กรุงเทพมหานคร</h5>
              <hr style={{ color: 'yellow', marginLeft: '0', borderWidth: '1px', width: '100%' }} />
            </CCol>
          </CRow>
          <CRow>
            <CCol className='align-self-end' xs={12} xl={6}>
              <CRow>
                <CCol xs={7} xl={12} className='align-self-end'>
                  <div className='tree'>
                    <div class='frame-setting'>
                      <CImage style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={this.state.user_info.pic_profile ? `${process.env.REACT_APP_STORAGE_ENDPOINT}/${this.state.user_info.pic_profile}` : '/default_user.png'} fluid />
                    </div>
                  </div>
                </CCol>
                <CCol xs={5} xl={0} className='d-block d-xl-none align-self-end '>
                  <h5 className='text-capitalize text-pri font-name text-nowrap'>
                    {this.state.user_info.first_name} {this.state.user_info.last_name}
                  </h5>
                  <h5 className='text-capitalize text-pri font-post text-nowrap'>
                    <span className='text-white user-post'>Position : </span>
                    {this.state.user_info.data.job_title}
                  </h5>
                  <CImage src={officeRight} width={120} className='mt-1' align='end' />
                </CCol>
              </CRow>
            </CCol>
            {/* <div className='mt-5 d-block d-xl-none'></div> */}
            <CCol xl={6} className='align-self-center text-white d-none d-xl-block'>
              <h3>ยินดีต้อนรับสู่</h3>
              <h1 className='text-pri text-uppercase'>Lifelong Learning Platform</h1>
              <h5>เพื่อการเรียนรู้ตามความต้องการ (Learning On-Demand)</h5>
              <h5>ของข้าราชการและบุคลากร กรุงเทพมหานคร</h5>
              <hr style={{ color: 'yellow', marginLeft: '0', borderWidth: '2px', width: '100%' }} />
              <h5 className='text-capitalize text-pri'>
                {this.state.user_info.first_name} {this.state.user_info.last_name}
              </h5>
              <span className='text-white d-inline-block'>Position</span>
              <h5 className='text-capitalize d-inline-block' style={{ marginLeft: '6px', color: 'rgb(113,2,18)' }}>
                {this.state.user_info.data.job_title}
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}
