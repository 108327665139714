import React from 'react'
import { CRow, CCol, CImage } from '@coreui/react'
import { Link } from 'react-router-dom'
import handFooter from '../assets/images/handFooter.svg'
import '../index.css'
export const BtnFooter = () => {
  return (
    <CRow>
      <CCol>
        <Link to={'#'} onClick={() => window.scrollTo(0, 0)}>
          <CImage className='btn-footer' align='center' src={handFooter} fluid />
        </Link>
      </CCol>
    </CRow>
  )
}
