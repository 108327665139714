import React, { Component } from 'react'
// import FrameHeaderLeftMenu from '../frame/header_left_menu'
import { CAlert, CContainer, CRow, CCol, CCardImage, CCard, CCardBody, CCardTitle, CCardText, CCardFooter, CImage, CButton } from '@coreui/react'
import { apiGetCourse } from '../../services/api/course'
import lodash from 'lodash'
import Swal from 'sweetalert2'
import bgCourse from '../../assets/images/bgCourse.png'
import btnLearn from '../../assets/images/btnLearn.png'
import play from '../../assets/images/play.png'
import { BtnFooter } from '../btnFooter'
import { Link } from 'react-router-dom'
import '../../index.css'

class ActiveCourseDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      my_courses: null,
    }
  }

  loadMyCourse = async () => {
    let course_data = await apiGetCourse(1, 1000, [], [{ key: 'is_course_complete', value: '0' }])

    this.setState({
      my_courses: lodash.map(course_data.data.data, function (course) {
        return {
          id: course.id,
          course_name: course.course_name.replaceAll('"', '').replace(')', '').split('-'),
          learning_progress: course.course_student[0].learning_progress,
          course_is_publish: course.course_is_publish,
          course_data: course.course_data,
          course_logo: course.course_logo,
        }
      }),
    })
  }

  redirectCourse = (course) => {
    if (course.course_is_publish) window.location.replace('/course/' + course.id + '/detail')
    else {
      Swal.fire({
        icon: 'error',
        text: 'วิชา ' + course.course_name + ' ไม่สามารถเข้าศึกษาได้ในขณะนี้',
      })
    }
  }
  componentDidMount() {
    this.loadMyCourse()
  }

  render() {
    const bgStyle = {
      backgroundImage: `url(${bgCourse})`,
      backgroundColor: '#edeeee',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'bottom center',
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      // justifyContent: 'center',
    }
    return (
      <>
        <div style={bgStyle}>
          <CContainer className='w-my' style={{ marginBottom: '13%' }} fluid>
            <CRow className='text-center mt-5 mb-5 mb-md-0'>
              <div className='strike'>
                <div>
                  <h1 className='text-sec'>หลักสูตรที่กำลังศึกษาอยู่</h1>
                </div>
              </div>
            </CRow>
            <CRow xs={{ cols: 1 }} md={{ cols: 3 }}>
              {this.state.my_courses && this.state.my_courses.length === 0 && (
                <CCol xs>
                  <CAlert color='info' className='text-center'>
                    คุณยังไม่ลงทะเบียน กรุณากดที่เมนู คอร์สเรียนทั้งหมด เพื่อลงทะเบียน
                  </CAlert>
                </CCol>
              )}
              {this.state.my_courses && this.state.my_courses.length > 0 && (
                <>
                  {this.state.my_courses.map((course, i) => (
                    <React.Fragment key={course.id}>
                      {course.course_is_publish && (
                        <>
                          <CCol xs={6} className='d-block d-md-none mb-4'>
                            <CImage className='border-image bg-grey img-at-course w-100' align='center' src={`${process.env.REACT_APP_STORAGE_ENDPOINT}/${course.course_logo}`} fluid />
                            <div id='monitorLeg' className='mt-2 mb-3 bg-grey'></div>
                          </CCol>
                          <CCol xs={6} className='d-block d-md-none mb-4'>
                            <Link to={'#'} onClick={() => this.redirectCourse(course)}>
                              <h5 className='font-h4'>{course.course_name[0]}</h5>
                              <h5 className='fw-normal font-h4'>{course.course_name[1]}</h5>
                            </Link>
                            <hr className='m-0 w-100 text-pink' />
                            <div className='d-flex mt-3 font-h5'>
                              กำลังศึกษาอยู่&nbsp;&nbsp;&nbsp;
                              <span className='text-sec font-h5'>
                                <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />
                                &nbsp; {course.learning_progress}%
                              </span>
                            </div>
                            <CButton className='bg-transparent border-0' onClick={() => this.redirectCourse(course)}>
                              <CImage width={200} src={btnLearn} fluid />
                            </CButton>
                          </CCol>
                          <CCol className='mt-5 d-none d-md-block'>
                            <CCard className='bg-transparent h-100 border-0'>
                              <CCardImage orientation='top' className='border-image bg-grey img-at-course w-100' src={`${process.env.REACT_APP_STORAGE_ENDPOINT}/${course.course_logo}`} fluid />
                              <div id='monitorLeg' className='mt-2 mb-3 bg-grey'></div>
                              <CCardBody>
                                <CCardTitle style={{ borderBottom: '1px solid #f185b7' }}>
                                  <Link to={'#'} onClick={() => this.redirectCourse(course)}>
                                    <h5>{course.course_name[0]}</h5>
                                    <h5 className='fw-normal'>{course.course_name[1]}</h5>
                                  </Link>
                                </CCardTitle>
                                <CCardText className='d-flex mt-3'>
                                  กำลังศึกษาอยู่&nbsp;&nbsp;&nbsp;
                                  <h5 className='text-sec'>
                                    <span>
                                      <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />
                                      &nbsp; {course.learning_progress}%
                                    </span>
                                  </h5>
                                </CCardText>
                              </CCardBody>
                              <CCardFooter className='bg-transparent border-0 text-end'>
                                <CButton className='bg-transparent border-0' onClick={() => this.redirectCourse(course)}>
                                  <CImage width={200} src={btnLearn} fluid />
                                </CButton>
                                <hr className='text-start' style={{ color: 'white', width: '100%', marginTop: '-54px', marginLeft: '0px' }} />
                              </CCardFooter>
                            </CCard>
                          </CCol>
                        </>
                      )}
                      {!course.course_is_publish && (
                        <>
                          <CCol className='mt-5' key={course.id} xs>
                            <CCard className='bg-transparent h-100 border-0'>
                              <CCardImage orientation='top' className='border-image bg-grey img-at-course w-100' src={''} fluid />
                              <div id='monitorLeg' className='mt-2 mb-3 bg-grey'></div>
                              <CCardBody>
                                <CCardTitle style={{ borderBottom: '1px solid #f185b7' }}>
                                  <h5>{course.course_name[0]}</h5>
                                  <h5 className='fw-normal'>{course.course_name[1]}</h5>
                                </CCardTitle>
                                <CCardText className='d-flex mt-3'>
                                  {/* <hr style={{ color: '#f185b7' }} /> */}
                                  <div className='text-start fw-bold' style={{ color: 'red' }}>
                                    หลักสูตรไม่พร้อมให้เข้าศึกษา
                                  </div>
                                </CCardText>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              <div className='d-block d-md-none' style={{ marginTop: '50%' }}></div>
            </CRow>
          </CContainer>
        </div>
        <CContainer style={{ background: '#fac4dd', height: '20vh' }} fluid>
          <BtnFooter></BtnFooter>
        </CContainer>
      </>
    )
  }
}

export default ActiveCourseDashboard
