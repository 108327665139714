import React, { Component } from 'react'
import { apiGetCourseAssignmentStudent, apiPutCourseAssignmentMy } from '../../services/api/assignment'
import { CModal, CModalBody, CModalTitle, CModalHeader } from '@coreui/react'

class ModalAssignmentPDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
      assignment: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.assignment && (!prevProps.assignment || prevProps.assignment.id !== this.props.assignment.id)) {
      apiGetCourseAssignmentStudent(parseInt(this.props.assignment.id)).then((assignment_res) => {
        if (assignment_res.data.assignment_type === 'pdf') {
          this.setState({
            assignment: assignment_res.data,
          })
        }
      })
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    return (
      this.state.assignment && (
        <CModal fullscreen={true} visible={this.props.show} onClose={this.handleClose}>
          <CModalHeader closeButton>
            <CModalTitle>{this.state.assignment.assignment_name}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <iframe style={{ border: '1px solid #666CCC' }} src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + this.state.assignment.assignment_data.url} frameBorder='1' scrolling='auto' height='99%' width='100%'></iframe>
          </CModalBody>
        </CModal>
      )
    )
  }
}

export default ModalAssignmentPDF
