import React, { Component } from 'react'
import { CModal, CModalHeader, CModalBody, CModalTitle, CForm, CButton, CFormInput } from '@coreui/react'

class ModalAddAssignmentFolder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
      modal_data: {
        assignment_folder_name: '',
        rank: '',
      },
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    let modal_data = this.state.modal_data

    modal_data[nam] = val

    this.setState({ modal_data: modal_data })
  }

  handleClose = () => {
    this.props.handleClose()
  }

  modalSubmit = () => {
    this.props.modalSubmit(this.state.modal_data)
  }

  render() {
    return (
      <CModal visible={this.props.show} onClose={this.handleClose}>
        <CModalHeader closeButton>
          <CModalTitle>เพิ่มกลุ่มของเนื้อหา</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormInput className='mb-2' label='ชื่อกลุ่มของเนื้อหา' type='text' placeholder='' name='assignment_folder_name' onChange={this.myChangeHandler} />
            <CFormInput className='mb-2' label='ลำดับ' type='number' min='0' max='1000' step='1' placeholder='' name='rank' onChange={this.myChangeHandler} />
            <CButton type='button' onClick={this.modalSubmit}>
              เพิ่ม
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
    )
  }
}

export default ModalAddAssignmentFolder
