import React, { Component } from 'react'
import { CModal, CModalHeader, CModalBody, CModalTitle, CForm, CFormInput, CButton } from '@coreui/react'

class ModalAddCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
      modal_data: {
        course_name: '',
      },
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    let modal_data = this.state.modal_data

    modal_data[nam] = val

    this.setState({ modal_data: modal_data })
  }

  handleClose = () => {
    this.props.handleClose()
  }

  modalSubmit = () => {
    this.props.modalSubmit(this.state.modal_data.course_name)
  }

  render() {
    return (
      <CModal visible={this.props.show} onClose={this.handleClose}>
        <CModalHeader closeButton>
          <CModalTitle>เพิ่มรายวิชาใหม่</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormInput className='mb-3' type='text' id='frmRegisterFirstname' placeholder='ชื่อรายวิชาใหม่' name='course_name' onChange={this.myChangeHandler} />
            <CButton type='button' onClick={this.modalSubmit}>
              เพิ่มรายวิชา
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
    )
  }
}

export default ModalAddCourse
