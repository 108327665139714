import axios from '../../config/axios';


const apiImportStudent = async (file) => {
    // console.log(file[0].name)
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/student/import_excel`, data ,{
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportInstructor = async (file) => {
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/instructor/import_excel`, data ,{
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const registerUser = async (username, password, user_info, user_data) => {
    let payload = {
        user : user_info,
        method : {
            "method_type" : "basic",
            "username" : username,
            "password" : password
        },
        "user_date" : user_data
    }

    try {
        const result = await axios.post(`/v1/user/register`, payload ,{ })
        return result
    } catch (error) {
        return error && error.response
    }
}


export {
    apiImportStudent,
    apiImportInstructor,
    registerUser
  }