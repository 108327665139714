import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HeaderTop from '../header/top'
import { CImage, CNavbar, CContainer, CNavLink, CNavbarText } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faUsers } from '@fortawesome/free-solid-svg-icons'
import dashboardIcon from '../../assets/images/dashboardIcon.png'
import dashboardIcon_Active from '../../assets/images/dashboardIcon_Active.png'
import courseIcon from '../../assets/images/courseIcon.png'
import courseIcon_Active from '../../assets/images/courseIcon_Active.png'
import ProfileSettingIcon from '../../assets/images/ProfileSettingIcon.png'
import ProfileSettingIcon_Active from '../../assets/images/ProfileSettingIcon_Active.png'
import lodash from 'lodash'
import '../../index.css'

class FrameHeaderLeftMenu extends Component {
  constructor(props) {
    super(props)
    let user_info = null
    let is_admin = false
    if (localStorage.getItem('user_info')) {
      try {
        user_info = JSON.parse(localStorage.getItem('user_info'))
        // console.log('user_info', user_info)
        let chk_role = lodash.filter(user_info.roles, { id: 1 })
        if (chk_role.length > 0) is_admin = true
      } catch (e) {}
    }
    this.state = {
      user_info: user_info,
      is_admin: is_admin,
      pageNow: '',
      pageDes: '',
      dashboard: 'DASHBOARD',
      allCourse: 'คอร์สเรียนทั้งหมด',
      setting: 'ตั้งค่าข้อมูลส่วนตัว',
    }
    if (this.props.active_menu === 'dashboard') {
      this.state.pageNow = 'DASHBOARD'
      this.state.pageDes = 'หลักสูตรที่กำลังศึกษาอยู่'
    }
    if (this.props.active_menu === 'course_list') {
      this.state.pageNow = 'คอร์สเรียนทั้งหมด'
      this.state.pageDes = 'หลักสูตรที่เปิดสอน'
    }
    if (this.props.active_menu === 'setting') {
      this.state.pageNow = 'ตั้งค่า'
      this.state.pageDes = 'ข้อมูลส่วนตัว'
    }
    if (this.props.active_menu === 'admin_course') {
      this.state.pageNow = 'ตั้งค่า'
      this.state.pageDes = 'จัดการคอร์สเรียน'
    }
    if (this.props.active_menu === 'admin_user') {
      this.state.pageNow = 'ตั้งค่า'
      this.state.pageDes = 'จัดการผู้ใช้งาน'
    }
  }

  render() {
    return (
      <>
        <HeaderTop />
        <CNavbar colorScheme='light'>
          <CContainer className='w-my' fluid>
            <CNavbarText component={Link} to='#'>
              <h4 className='font-locate-pri-nav fw-normal mt-xl-2 text-pri'>
                {this.state.pageNow} &nbsp;
                <span className='d-block d-xl-inline text-sec font-locate-sec-nav '>{this.state.pageDes}</span>
              </h4>
            </CNavbarText>
            <div className='d-flex text-center align-item-center justify-content-center'>
              <CNavLink component={Link} to='/dashboard' className='mx-1 mx-md-2'>
                <CImage className='img-menu-nav' src={this.props.active_menu === 'dashboard' ? dashboardIcon_Active : dashboardIcon} fluid />
                <span className={this.state.is_admin ? 'd-none d-xl-block' : 'd-none d-xl-inline'}>{this.state.dashboard}</span>
              </CNavLink>
              <CNavLink component={Link} to='/course/all' className='mx-1 mx-md-2'>
                <CImage className='img-menu-nav' src={this.props.active_menu === 'course_list' ? courseIcon_Active : courseIcon} fluid />
                <span className={this.state.is_admin ? 'd-none d-xl-block' : 'd-none d-xl-inline'}>{this.state.allCourse}</span>
              </CNavLink>
              <CNavLink component={Link} to='/setting' className='mx-1 mx-md-2'>
                <CImage className='img-menu-nav' src={this.props.active_menu === 'setting' ? ProfileSettingIcon_Active : ProfileSettingIcon} fluid />
                <span className={this.state.is_admin ? 'd-none d-xl-block' : 'd-none d-xl-inline'}>{this.state.setting}</span>
              </CNavLink>
              {this.state.is_admin && (
                <>
                  <CNavLink component={Link} to='/admin/course'>
                    <FontAwesomeIcon icon={faTable} className='mx-1 mx-md-2 text-sec' style={{ fontSize: 'clamp(1rem,8vw, 4.3rem)' }} />
                    <span className={this.state.is_admin ? 'd-none d-xl-block' : 'd-none d-xl-inline'}>จัดการคอร์สเรียน</span>
                  </CNavLink>
                  <CNavLink component={Link} to='/admin/user'>
                    <FontAwesomeIcon icon={faUsers} className='mx-1 mx-md-2 text-sec' style={{ fontSize: 'clamp(1rem,8vw, 4.3rem)' }} />
                    <span className={this.state.is_admin ? 'd-none d-xl-block' : 'd-none d-xl-inline'}>จัดการผู้ใช้งาน</span>
                  </CNavLink>
                </>
              )}
            </div>
          </CContainer>
        </CNavbar>
      </>
    )
  }
}

export default FrameHeaderLeftMenu
