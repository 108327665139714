import React, { Component } from 'react'
import { CContainer, CImage, CRow, CCol, CButton } from '@coreui/react'
import { Link } from 'react-router-dom'
import { apiGetCourse } from '../../services/api/course'
import lodash from 'lodash'
import Swal from 'sweetalert2'
import dashboardIcon from '../../assets/images/dashboardIcon.png'
import officeLeft from '../../assets/images/officeLeft.png'
import btnLearn from '../../assets/images/btnLearn.png'
import play from '../../assets/images/play.png'

export default class IntroDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      my_courses: null,
    }
  }

  loadMyCourse = async () => {
    let course_data = await apiGetCourse(1, 1000, [], [{ key: 'is_course_complete', value: '0' }])

    this.setState({
      my_courses: lodash.map(course_data.data.data, function (course) {
        return {
          id: course.id,
          course_name: course.course_name.replaceAll('"', '').replace(')', '').split('('),
          learning_progress: course.course_student[0].learning_progress,
          course_is_publish: course.course_is_publish,
          course_data: course.course_data,
          course_logo: course.course_logo,
        }
      }),
    })
  }

  redirectCourse = (course) => {
    if (course.course_is_publish) window.location.replace('/course/' + course.id + '/detail')
    else {
      Swal.fire({
        icon: 'error',
        text: 'วิชา ' + course.course_name + ' ไม่สามารถเข้าศึกษาได้ในขณะนี้',
      })
    }
  }
  componentDidMount() {
    this.loadMyCourse()
  }
  render() {
    const bgStyle = {
      backgroundImage: `url(${officeLeft})`,
      backgroundColor: 'rgb(224, 225, 225)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '30%',
      backgroundPosition: 'bottom left',
      overflow: 'hidden',
      //   height: '500',
    }
    return (
      <div style={bgStyle}>
        <CContainer>
          <CRow className='mt-5 mb-5 '>
            <CCol xs={12} md={4} className='text-center m-auto'>
              <div className='d-none d-md-block'>
                <Link to='/dashboard'>
                  <h1 className='text-sec'>DASHBOARD</h1>
                </Link>
                <h5 className='fw-normal'>
                  หลักสูตรที่กำลังศึกษาอยู่ <Link to={'/course/all'}>ทั้งหมด</Link>
                </h5>
                <hr className='m-auto w-75 mt-3 text-sec' />
              </div>
              <CImage src={dashboardIcon} className='mt-3 mb-3 w-dash-logo' width={300} fluid />
              <div className='d-block d-md-none mb-5'>
                <Link to='/dashboard'>
                  <h1 className='text-pri'>DASHBOARD</h1>
                </Link>
                <h5 className='fw-normal'>
                  หลักสูตรที่กำลังศึกษาอยู่
                  <Link to={'/course/all'} className='text-sec'>
                    ทั้งหมด
                  </Link>
                </h5>
              </div>
            </CCol>
            {this.state.my_courses && this.state.my_courses.length === 0 && (
              <CCol xs={6} md={4}>
                คุณยังไม่ลงทะเบียน กรุณากดที่เมนู คอร์สเรียนทั้งหมด เพื่อลงทะเบียน
              </CCol>
            )}
            {this.state.my_courses && this.state.my_courses.length > 0 && (
              <>
                {this.state.my_courses.slice(0, 2).map((course, i) => (
                  <React.Fragment key={course.id}>
                    {course.course_is_publish && (
                      <>
                        <CCol xs={6} md={4}>
                          <CImage className='border-image bg-grey' align='center' src={`${process.env.REACT_APP_STORAGE_ENDPOINT}/${course.course_logo}`} fluid />
                          <div id='monitorLeg' className='mt-2 mb-3 bg-grey '></div>
                        </CCol>
                        <CCol xs={6} md={4}>
                          <Link to={'#'} onClick={() => this.redirectCourse(course)}>
                            <h5 className=' text-sec font-h4'>{course.course_name[0]}</h5>
                            {/* <h5 className='fw-normal font-h5'>{course.course_name[1]}</h5> */}
                          </Link>
                          <hr className='m-0 w-100 text-pink' />
                          <div className='d-flex mt-3 font-p'>
                            กำลังศึกษาอยู่&nbsp;&nbsp;
                            <h5 className='text-sec font-h4'>
                              <CImage src={play} width={25} style={{ marginTop: '-10px' }} fluid />
                              &nbsp;
                              {course.learning_progress}%
                            </h5>
                          </div>
                          <CButton className='bg-transparent border-0 ' onClick={() => this.redirectCourse(course)}>
                            <CImage width={200} src={btnLearn} fluid />
                          </CButton>
                        </CCol>
                      </>
                    )}
                    <CCol xs={0} md={4}></CCol>
                    {!course.course_is_publish && (
                      <>
                        <CCol key={course.id}>
                          <CImage className='border-image bg-grey' align='center' src={`/top_logo.png`} fluid />
                          <div id='monitorLeg' className='mt-2 mb-3 bg-grey'></div>
                        </CCol>
                        <CCol>
                          <h5>{course.course_name[0]}</h5>
                          <h5 className='fw-normal'>{course.course_name[1]}</h5>
                          <hr style={{ color: '#f185b7' }} />
                          <div className='mt-3 text-end'>
                            <div className='text-start'>หลักสูตรไม่พร้อมให้เข้าศึกษา</div>
                          </div>
                        </CCol>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </CRow>
          <CRow className='mt-5 mb-5'></CRow>
        </CContainer>
      </div>
    )
  }
}
