import axios from 'axios'
// import dotenv from 'dotenv'
// dotenv.config()

console.log('Axios config called')

let url = process.env.REACT_APP_API_ENDPOINT
console.log(process.env.REACT_APP_API_ENDPOINT, '<<<<++++++++++++++++')
const instance = axios.create({
  baseURL: url,
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      //clear all store data
      localStorage.removeItem('expire')
      localStorage.removeItem('token')
      localStorage.removeItem('user_info')
      window.location.replace('/')
    }
    return Promise.reject(error)
  }
)

export default instance
