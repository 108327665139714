import axios from '../../config/axios';


const apiLogin = async (payload) => {
    try {
      const result = await axios.post(`/v1/auth/login`,payload)
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiLogout = async () => {
    try {
      const result = await axios.post(`/v1/auth/logout`,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response
    }
  }


  const apiDeleteAuth = async () => {
    try {
      const result = await axios.delete(`/v1/user/auth/my`,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiSendForgotPassword = async (email) => {
      await axios.post(`/v1/auth/request_reset_pass`, {email})
      return true
  }

  const apiCheckForgotPasswordToken = async (token) => {
    let chk = await axios.get(`/v1/auth/reset_pass/` + token)
    return chk.data;
}

  const apiRedeemForgotPasswordToken = async (token, password) => {
    await axios.post(`/v1/auth/reset_pass/` + token, { password : password})
    return true
  }

  export {
    apiLogin,
    apiLogout,
    apiDeleteAuth,
    apiSendForgotPassword,
    apiCheckForgotPasswordToken,
    apiRedeemForgotPasswordToken
  }