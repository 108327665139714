import React, { Component } from 'react'
import HeaderTop from './header/top'
import IntroWelcome from './home/intro_welcome'
import IntroMenu from './home/intro_menu'
import IntroDashboard from './home/intro_dashboard'
import IntroCourse from './home/intro_course'

class Intro extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    console.log('Load Intro')
  }

  render() {
    // const activeLogin = () => {
    //   this.props.history.push('/login')
    // }

    return (
      <>
        <HeaderTop></HeaderTop>
        <IntroWelcome></IntroWelcome>
        <IntroMenu></IntroMenu>
        <IntroDashboard></IntroDashboard>
        <IntroCourse></IntroCourse>
      </>
    )
  }
}

export default Intro
