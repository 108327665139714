import React, { Component } from 'react'
import { CCard, CCol, CForm, CInputGroup, CInputGroupText, CFormInput, CCardBody, CButton, CContainer, CRow, CCardTitle, CCardSubtitle } from '@coreui/react'
import HeaderTop from '../header/top'
import Swal from 'sweetalert2'
import { apiSendForgotPassword } from '../../services/api/auth'
import isEmail from 'validator/lib/isEmail'
import bgCourse from '../../assets/images/bgCourse.png'

class AuthForgorPasswordFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      is_complete: false,
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeForgotPassword = async (event) => {
    console.log(this.state)
    event.preventDefault()
    if (this.state.email.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'กรุณาระบุอีเมล์',
      })
      return
    }

    try {
      let result = await apiSendForgotPassword(this.state.email)
      console.log(result)
    } catch (e) {
      console.error('e', e)
    }

    this.setState({ is_complete: true })
  }

  render() {
    const bgStyle = {
      backgroundImage: `url(${bgCourse})`,
      backgroundColor: '#edeeee',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'bottom center',
      overflow: 'hidden',
      height: '100vh',
      display: 'flex',
      // justifyContent: 'center',
    }
    return (
      <>
        <HeaderTop />
        <div style={bgStyle}>
          <CContainer className='w-my' fluid>
            <CCard className='shadow w-my mt-5 mx-auto'>
              <CCardBody>
                <CCardTitle>
                  <h3 className='text-center mt-3 text-sec'>ลืมรหัสผ่าน</h3>
                  <hr className='bg-sec text-sec mx-auto'></hr>
                </CCardTitle>
                <CRow>
                  <CCol className='mt-4'>
                    {this.state.is_complete ? (
                      <CCardSubtitle className='mb-3 text-center'>เราได้ส่งอีเมล์ลิงก์สำหรับรีเซ็ตรหัสผ่านถึงคุณแล้ว ควรปรากฏในกล่องจดหมายของคุณภายในห้านาทีถัดไป หากคุณยังไม่เห็น โปรดตรวจสอบที่ไฟลเดอร์สแปมของคุณ !!</CCardSubtitle>
                    ) : (
                      <CForm onSubmit={this.activeForgotPassword}>
                        <CCardSubtitle className='mb-2 text-medium-emphasis text-center'>ระบุที่อยู่อีเมลที่คุณใช้ และเราจะส่งคำแนะนำในการรีเซ็ตรหัสผ่านของคุณ</CCardSubtitle>
                        <CInputGroup className='mb-4' controlId='frmRegisterUsername'>
                          <CInputGroupText>E-mail</CInputGroupText>
                          <CFormInput className='form-input' type='email' placeholder='' name='email' onChange={this.myChangeHandler} />
                        </CInputGroup>
                        <CButton className='w-100 mb-3 mt-2' color='danger' type='submit'>
                          <div className='text-white fs-5'>Send Email Reset Password</div>
                        </CButton>
                      </CForm>
                    )}
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </div>
      </>
    )
  }
}

export default AuthForgorPasswordFrm
