import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import { apiUploadFile } from '../../services/api/course'
import { apiEditProfile, apiGetInfo, apiEditUserPassword } from '../../services/api/user'
import { apiDeleteAuth } from '../../services/api/auth'
import { CContainer, CRow, CCol, CButton, CImage, CForm, CFormInput } from '@coreui/react'
import Swal from 'sweetalert2'
import lodash from 'lodash'
import officeLeft from '../../assets/images/officeLeft.png'
import officeRight from '../../assets/images/officeRight.png'
import btnSaveAvatar from '../../assets/images/btnSaveAvatar.png'
import btnSaveData from '../../assets/images/btnSaveData.png'
import btnSaveDataActive from '../../assets/images/btnSaveDataActive.png'
import btnDelAccount from '../../assets/images/btnDelAccount.png'
import '../../index.css'
class SettingUser extends Component {
  constructor(props) {
    super(props)

    let user_info = null
    if (localStorage.getItem('user_info')) {
      try {
        user_info = JSON.parse(localStorage.getItem('user_info'))
      } catch (e) {}
    }
    this.state = {
      user_info: user_info,
      edit_user: {
        first_name: user_info.first_name,
        last_name: user_info.last_name,
        job_title: user_info.data.job_title,
        old_password: '',
        new_password: '',
        new_password_re: '',
      },
    }
  }

  updateProfilePicture = async (e) => {
    try {
      e.preventDefault()
      let upload_result = await apiUploadFile(document.getElementById('new_profile_pic').files[0])
      console.log('upload_result', upload_result)

      lodash.cloneDeep(this.state.user_info)

      if (upload_result.status) {
        let store_at = upload_result.data.result.tmp_file
        let updated_user = {
          first_name: this.state.user_info.first_name,
          last_name: this.state.user_info.last_name,
          user_data: this.state.user_info.data,
          pic_profile_url: store_at,
        }
        await apiEditProfile(updated_user)

        let user_info = await apiGetInfo()
        localStorage.setItem('user_info', JSON.stringify(user_info.data))

        await Swal.fire({
          icon: 'success',
          text: 'บันทึกข้อมูลเรียบร้อย',
        })

        window.location.reload()
      } else {
        await Swal.fire({
          icon: 'error',
          text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
        })
      }
    } catch (e) {
      console.log(e)
      await Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
      })
    }
  }

  updateProfile = async (e) => {
    try {
      e.preventDefault()
      console.log('this.state.edit_user', this.state.edit_user)

      let updated_user = {
        first_name: this.state.edit_user.first_name,
        last_name: this.state.edit_user.last_name,
        user_data: this.state.user_info.data,
      }
      updated_user.user_data.job_title = this.state.edit_user.job_title
      console.log('updated_user', updated_user)
      await apiEditProfile(updated_user)

      let user_info = await apiGetInfo()
      localStorage.setItem('user_info', JSON.stringify(user_info.data))

      await Swal.fire({
        icon: 'success',
        text: 'บันทึกข้อมูลเรียบร้อย',
      })

      window.location.reload()
    } catch (e) {
      console.log(e)
      await Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
      })
    }
  }

  updatePassword = async (e) => {
    try {
      e.preventDefault()
      console.log('this.state.edit_user', this.state.edit_user)

      if (this.state.edit_user.old_password.trim().length === 0) {
        Swal.fire({
          icon: 'error',
          text: 'กรุณาระบุรหัสผ่านเก่า',
        })
        return
      }

      if (this.state.edit_user.new_password.trim().length === 0) {
        Swal.fire({
          icon: 'error',
          text: 'กรุณาระบุรหัสผ่านใหม่',
        })
        return
      }

      if (this.state.edit_user.new_password_re !== this.state.edit_user.new_password) {
        Swal.fire({
          icon: 'error',
          text: 'รหัสผ่านใหม่ที่ระบุมาไม่เหมือนกัน',
        })
        return
      }

      let updated_user = {
        old_password: this.state.edit_user.old_password,
        new_password: this.state.edit_user.new_password,
      }
      console.log('updated_user', updated_user)
      await apiEditUserPassword(updated_user)

      let user_info = await apiGetInfo()
      // console.log(user_info)
      localStorage.setItem('user_info', JSON.stringify(user_info.data))

      await Swal.fire({
        icon: 'success',
        text: 'บันทึกข้อมูลเรียบร้อย กรุณาเข้าสู่ระบบใหม่',
      })

      window.location.reload()
    } catch (e) {
      console.log(e)
      await Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด รหัสผ่านไม่ถูกต้อง',
      })
    }
  }

  deleteUser = async () => {
    try {
      console.log('ลบข้อมูล', this.state.edit_user)

      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการจะลบบัญชีสมาชิกใช่หรือไม่?',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let resultMyAuth = await apiDeleteAuth()
          console.log('delete', resultMyAuth)
          await Swal.fire({
            icon: 'success',
            text: 'ลบบัญชีสมาชิกเรียบร้อย กรุณาเข้าสู่ระบบใหม่',
            showConfirmButton: false,
            timer: 1000,
          })

          localStorage.removeItem('token')
          localStorage.removeItem('expire')
          localStorage.removeItem('user_info')
          setInterval(function () {
            window.location.replace('/')
          }, 500)
        }
      })
    } catch (e) {
      console.log(e)
      await Swal.fire({
        icon: 'error',
        text: 'เกิดข้อผิดพลาด รหัสผ่านไม่ถูกต้อง',
      })
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    let edit_user = this.state.edit_user

    edit_user[nam] = val

    this.setState({ edit_user: edit_user })
  }

  render() {
    const bgStyle = {
      backgroundColor: '#f2adb6',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const bgStylePass = {
      backgroundImage: `url(${officeLeft})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '30%',
      backgroundPosition: 'bottom left',
      overflow: 'hidden',
      height: '465px',
    }

    return (
      <>
        <FrameHeaderLeftMenu active_menu='setting'></FrameHeaderLeftMenu>
        <div style={bgStyle}>
          <CContainer className='w-my' fluid>
            <CRow>
              <CCol className='align-self-end' xs={12} xl={6}>
                <CRow>
                  <CCol xs={7} xl={12} className='align-self-end'>
                    <div className='tree'>
                      <div class='frame-setting'>
                        <CImage style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={this.state.user_info.pic_profile ? `${process.env.REACT_APP_STORAGE_ENDPOINT}/${this.state.user_info.pic_profile}` : '/default_user.png'} fluid />
                      </div>
                    </div>
                  </CCol>
                  <CCol xs={5} xl={0} className='d-block d-xl-none align-self-end'>
                    <h5 className='text-capitalize text-pri font-name text-nowrap'>
                      {this.state.user_info.first_name} {this.state.user_info.last_name}
                    </h5>
                    <h5 className='text-capitalize text-pri font-post text-nowrap'>
                      <span className='text-white user-post'>Position : </span>
                      {this.state.user_info.data.job_title}
                    </h5>
                    <CImage src={officeRight} width={120} className='mt-1' align='end' />
                  </CCol>
                </CRow>
                <hr className='m-0 mb-4 w-100 text-white d-block d-xl-none' />
              </CCol>
              <CCol xs={12} xl={6}>
                <h3 className='text-pri mt-xl-5'>ข้อมูลส่วนตัว</h3>
                <hr style={{ color: 'white', marginLeft: '0', borderWidth: '2px', width: '100%' }} />
                <CForm onSubmit={this.updateProfilePicture}>
                  <CFormInput className='text-pri bg-tri border-white' type='file' placeholder='' />
                  <CButton type='submit' className='bg-transparent border-0 bth-setting'>
                    <CImage src={btnSaveAvatar} fluid />
                  </CButton>
                </CForm>
                <CForm onSubmit={this.updateProfile}>
                  <CRow>
                    <CCol xs={12} xl={8}>
                      <div className='fw-bold mb-3 text-capitalize text-pri'>
                        Name : {this.state.user_info.first_name} {this.state.user_info.last_name}
                      </div>
                      <CFormInput id='frmRegisterFirstname' className='text-pri bg-tri form-input border-white mb-3' type='text' placeholder='ชื่อ' name='first_name' defaultValue={this.state.edit_user.first_name} onChange={this.myChangeHandler} />
                      <CFormInput id='frmRegisterLastname' className='text-pri bg-tri form-input border-white mb-3' type='text' placeholder='นามสกุล' name='last_name' defaultValue={this.state.edit_user.last_name} onChange={this.myChangeHandler} />
                      <CFormInput id='frmRegisterJobTitle' className='text-pri bg-tri form-input border-white mb-3' type='text' placeholder='ตำแหน่ง' name='job_title' defaultValue={this.state.edit_user.job_title} onChange={this.myChangeHandler} />
                    </CCol>
                    <CCol xs={12} xl={4} className='align-self-end'>
                      <CButton type='submit' className='bg-transparent border-0 bth-setting'>
                        <CImage src={btnSaveData} fluid />
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        {/*--------------------------------------------------------------------- */}
        <div style={bgStylePass}>
          <CContainer className='w-my' fluid>
            <CRow>
              <CCol xs={12} xl={6}></CCol>
              <CCol xs={12} xl={6}>
                <CForm onSubmit={this.updatePassword}>
                  <CRow>
                    <CCol xs={12} xl={8}>
                      <h3 className='mt-5 text-pri'>เปลี่ยนรหัสผ่าน</h3>
                      <CFormInput className='form-input border-tri text-pri mb-3' type='password' placeholder='รหัสผ่านเดิม' name='old_password' onChange={this.myChangeHandler} />
                      <CFormInput className='form-input border-tri text-pri mb-3' type='password' placeholder='รหัสผ่านใหม่' name='new_password' onChange={this.myChangeHandler} />
                      <CFormInput className='form-input border-tri text-pri mb-3' type='password' placeholder='รหัสผ่านใหม่อีกครั้ง' name='new_password_re' onChange={this.myChangeHandler} />
                    </CCol>
                    <CCol xs={12} xl={4} className='align-self-end '>
                      <CButton type='submit' className='bg-transparent border-0 bth-setting'>
                        <CImage type='image' src={btnSaveDataActive} fluid />
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <CContainer style={{ backgroundColor: '#fac4dd', height: '20vh' }} fluid>
          <CRow>
            <CCol>
              <CForm className='text-md-center'>
                <CButton className='bg-transparent border-0' onClick={this.deleteUser}>
                  <CImage src={btnDelAccount} className='btn-del' fluid />
                </CButton>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
  }
}

export default SettingUser
