import React, { Component } from 'react'
import FrameHeader from '../frame/header'
import lodash from 'lodash'
import { CContainer, CImage, CRow, CCol, CButton } from '@coreui/react'
import { apiGetMyCourseDetail, apiEnrollCourse } from '../../services/api/course'
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faPlay, faQuestion, faStopCircle, faPauseCircle, faPlayCircle, faCheckCircle, faWindowMinimize, faFileSignature, faArrowCircleDown, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import CourseAssignmentVimeo from './assignment_vimeo'
import ModalAssignmentPDF from './assignment_modal_pdf'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import play from '../../assets/images/play.png'
import bgLearn from '../../assets/images/bgLearn.png'
import bgLeftRightGreyOffice from '../../assets/images/bgLeftRightGreyOffice.png'
import { BtnFooter } from '../btnFooter'
// import { useHistory } from 'react-router-dom'

class CourseAssignment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show_modal_assignment_pdf: false,
      active_assignment_pdf: null,
      course: null,
      course_assignment_folders: [],
      active_assignment_id: 0,
      active_video_assignment_id: 0,
      //active_quiz_assignment_id : 0,
      active_folder_id: -1,
      show_folder_id: {},
      course_assignment_structs: {},
      course_assignment_folder_summaries: {},
    }
  }

  loadCourseAssignment = async (init_show_folder = false) => {
    let course_data = await apiGetMyCourseDetail(parseInt(this.props.match.params.course_id))
    let course_assignment_folders = await apiGetListCourseAssignmentFolder(parseInt(this.props.match.params.course_id))

    let course_assignment_structs = {}
    let show_folder_id = {}
    let course_assignment_folder_summaries = {}

    course_assignment_structs[0] = lodash.sortBy(lodash.filter(course_data.data.course_assignment, { course_assignment_folder_id: null }), ['assignment_order'])
    for (let i = 0; i < course_assignment_folders.data.length; i++) {
      show_folder_id[course_assignment_folders.data[i].id.toString()] = false
      course_assignment_structs[course_assignment_folders.data[i].id] = lodash.sortBy(lodash.filter(course_data.data.course_assignment, { course_assignment_folder_id: course_assignment_folders.data[i].id }), ['assignment_order'])
      course_assignment_folder_summaries[course_assignment_folders.data[i].id] = {
        video: lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], { assignment_type: 'video' }).length,
        pdf: lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], { assignment_type: 'pdf' }).length,
      }
    }
    console.log('course_assignment_structs', course_assignment_structs)
    this.setState({
      ...(init_show_folder ? { show_folder_id: show_folder_id } : {}),
      course: course_data.data,
      course_assignment_folders: course_assignment_folders.data,
      course_assignment_structs: course_assignment_structs,
      course_assignment_folder_summaries: course_assignment_folder_summaries,
    })
  }

  componentDidMount() {
    this.loadCourseAssignment(true)
  }

  activeAssignment = async (assignment) => {
    console.log('active assignment', assignment)

    if (assignment.can_access === false) return

    if (assignment.assignment_type === 'quiz') {
      if (assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90.0) {
        Swal.fire({
          icon: 'info',
          text: 'คุณทำแบบทดสอบนี้ผ่านแล้ว',
        })
        return
      } else {
        window.location.href = '/course/' + assignment.course_id + '/quiz/' + assignment.id
        return
      }
    }

    if (assignment.assignment_type === 'pdf') {
      this.setState({
        show_modal_assignment_pdf: true,
        active_assignment_pdf: assignment,
      })
    } else {
      this.setState({
        show_modal_assignment_pdf: false,
        active_assignment_pdf: null,
      })
    }
    let active_assignment_id = assignment.id
    let active_video_assignment_id = assignment.assignment_type === 'video' ? assignment.id : 0
    //let active_quiz_assignment_id = assignment.assignment_type === "quiz" ? assignment.id : 0;
    let active_folder_id = assignment.course_assignment_folder_id || 0

    this.setState({
      active_assignment_id: active_assignment_id,
      active_video_assignment_id: active_video_assignment_id,
      //active_quiz_assignment_id : active_quiz_assignment_id,
      active_folder_id: active_folder_id,
    })

    this.loadCourseAssignment()
  }

  renderFolder = (folder, idx) => {
    return (
      <>
        <>
          <li key={idx} className='p-3 bg-transparent ' style={{ fontSize: '18px', borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
            <span className='fs-4' style={{ color: '#c7505f' }}>
              {folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;
            </span>
            {folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
            {this.state.course_assignment_structs[folder.id].map((assignment) => (
              <li>
                <span className='text-sec'> • </span>
                <span className='text-pri'>{assignment.assignment_name}</span>
                <span className='text-white'> | </span>
                <CButton className='div_assignment bg-transparent border-0' style={{ color: '#ef85b7' }} key={assignment.id} disabled={assignment.can_access === false ? 'disable' : this.state.active_assignment_id === assignment.id ? 'active' : ''} onClick={() => this.activeAssignment(assignment)}>
                  {/* {assignment.assignment_type === 'video' && <FontAwesomeIcon icon={faVideo} />} */}
                  {assignment.assignment_type === 'video' && this.state.active_assignment_id === assignment.id && <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />}

                  {assignment.assignment_type === 'video' && this.state.active_assignment_id !== assignment.id && (
                    <>
                      {/* {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 0 && <FontAwesomeIcon icon={faPauseCircle} />}
                      {(assignment.course_assignment_user_score.length === 0 || assignment.course_assignment_user_score[0].assignment_progr === 0) && <FontAwesomeIcon icon={faStopCircle} />}
                      {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90 && <FontAwesomeIcon icon={faCheckCircle} />} */}
                      {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 0 && <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />}
                      {(assignment.course_assignment_user_score.length === 0 || assignment.course_assignment_user_score[0].assignment_progr === 0) && <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />}
                      {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90 && <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />}
                    </>
                  )}

                  {assignment.assignment_type === 'quiz' && <FontAwesomeIcon icon={faQuestion} />}
                  {assignment.assignment_type === 'pdf' && <FontAwesomeIcon icon={faFileSignature} />}
                </CButton>
              </li>
            ))}
          </li>
        </>
      </>
    )
  }

  render() {
    const bgStyle1 = {
      background: `url(${bgLeftRightGreyOffice}) no-repeat bottom center, linear-gradient(to bottom, #f2adb6 50%, #ffff 50%)`,
      backgroundSize: `100%`,
      overflow: 'hidden',
    }
    const bgStyle2 = {
      background: `url(${bgLearn}), #e0e1e1`,
      backgroundPosition: 'left bottom',
      backgroundSize: '40%',
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      height: '100%',
    }
    return (
      <>
        <FrameHeaderLeftMenu active_menu='course_list' />
        <div style={bgStyle1}>
          <CContainer className='w-my' fluid>
            <CRow>
              <CCol className='text-center mt-5 mb-5'>
                <h4 className='fw-bold text-pri'>{this.state.course && this.state.course.course_name.replaceAll('"', '').replace(')', '').split('(')[0]}</h4>
                <h4 className='fw-normal text-white mb-4'>{this.state.course && this.state.course.course_name.replaceAll('"', '').replace(')', '').split('(')[1]}</h4>
                <div className='border-image bg-sec'>
                  <CourseAssignmentVimeo assignment_id={this.state.active_video_assignment_id} />
                </div>
                {this.state.course && (
                  <>
                    {this.state.course.course_student[0].is_course_complete === true && <span>ศึกษาวิชานี้สำเร็จแล้ว</span>}
                    {!this.state.course.course_student[0].is_course_complete && (
                      <>
                        <p className='fs-5 mt-3 text-end m-auto '>
                          กำลังศึกษาอยู่&nbsp;&nbsp;
                          <span className='fw-bold text-sec'>
                            <CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />
                            &nbsp;
                            {this.state.course.course_student[0].learning_progress}%
                          </span>
                        </p>
                        <p className='text-start mt-3 m-auto w-my'>
                          <span className='fw-bold text-pri'>{this.state.course.course_data.description.split(' ')[0]}&nbsp;</span>
                          {this.state.course.course_data.description.split(' ').slice(1).join(' ')}
                        </p>
                      </>
                    )}
                  </>
                )}
              </CCol>
            </CRow>
          </CContainer>
        </div>

        <div style={bgStyle2}>
          <CContainer style={{ marginBottom: '10%' }} fluid>
            <CRow className='mt-5 mb-5'>
              <CCol className='text-center' xs={12} md={6}>
                {this.state.course && (
                  <div className='w-my m-auto'>
                    <hr className='text-white' />
                    <h4 className='fw-normal text-sec' style={{ lineHeight: '2' }}>
                      {this.state.course.course_data.course_detail_1.split(' ').reverse().slice(1).reverse().join(' ')}
                      <h1 className='fw-normal text-pri' style={{ lineHeight: '1.5' }}>
                        <i>{this.state.course.course_data.course_detail_1.split(' ').pop()}</i>
                      </h1>
                    </h4>
                    <hr className='text-white' />
                  </div>
                )}
              </CCol>
              <CCol>
                <ul>
                  {this.state.course_assignment_folders.map((folder, idx) => this.renderFolder(folder, idx + 1))}
                  {
                    //(this.state.course_assignment_structs[0] && this.state.course_assignment_structs[0].length > 0) &&
                    //    this.renderFolder({id : 0, assignment_folder_name : "เนื้อหาเพิ่มเติม" })
                  }
                </ul>
              </CCol>
              <div className='mt-5 d-block d-md-none'></div>
            </CRow>
          </CContainer>
        </div>
        <CContainer className='bg-skypink' style={{ height: '20vh' }} fluid>
          <BtnFooter></BtnFooter>
        </CContainer>

        <ModalAssignmentPDF
          show={this.state.show_modal_assignment_pdf}
          assignment={this.state.active_assignment_pdf}
          handleClose={() => {
            this.setState({ show_modal_assignment_pdf: false })
          }}
        />
        <CButton
          className='div-back-btn border-0 fw-normal text-dark bg-sec'
          shape=''
          onClick={() => {
            window.location.replace('/course/' + this.state.course.id + '/detail')
          }}>
          <b>BACK</b>
        </CButton>
      </>
    )
  }
}

export default CourseAssignment
