import React, { Component } from 'react'
import { CContainer, CImage, CRow, CCol } from '@coreui/react'
import { Link } from 'react-router-dom'
import dashboardIcon from '../../assets/images/dashboardIcon.png'
import courseIcon from '../../assets/images/courseIcon.png'
import profileSettingIcon from '../../assets/images/ProfileSettingIcon.png'

export default class IntroMenu extends Component {
  render() {
    return (
      <CContainer>
        <CRow className='mt-3 mb-4 align-items-center text-center' xs={{ cols: 3 }}>
          <CCol>
            <Link to='/dashboard'>
              <CImage src={dashboardIcon} alt='dashboardIcon' style={{ maxHeight: '120px' }} className='mt-5 mb-4' fluid />
              <h5 className='text-dark font-h5 '>DASHBOARD</h5>
            </Link>
          </CCol>
          <CCol>
            <Link to='/course/all'>
              <CImage src={courseIcon} alt='courseIcon' style={{ maxHeight: '120px' }} className='mt-5 mb-4' fluid />
              <h5 className='text-dark font-h5 '>คอร์สเรียนทั้งหมด</h5>
            </Link>
          </CCol>
          <CCol>
            <Link to='/setting'>
              <CImage src={profileSettingIcon} alt='profileSettingIcon' style={{ maxHeight: '120px' }} className='mt-5 mb-4' fluid />
              <h5 className='text-dark font-h5 '>ตั้งค่าข้อมูลส่วนตัว</h5>
            </Link>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <br />
            <br />
          </CCol>
        </CRow>
      </CContainer>
    )
  }
}
